import { Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setDoctor,
  setSpecialty,
} from "../../redux/appointment-scheduler-params";
import { RootState } from "../../store";
import Doctor from "../../models/doctor";
import DoctorSelect from "../doctors/DoctorSelect";
import SpecialtySelect from "../specialties/SpecialtySelect";
import Specialty from "../../models/specialty";
import { countErrors, getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";

const DoctorPicker = () => {
  const dispatch = useDispatch();
  const appointmentScheduleParams = useSelector(
    (state: RootState) => state.appointmentScheduler
  );

  const errors = useSelector(
    (state: RootState) => state.appointmentScheduler.errors
  );
  const onDoctorChange = (newDoctor?: Doctor) => {
    dispatch(setDoctor(newDoctor));
  };
  const onSpecialtyChange = (newSpecialty?: Specialty) => {
    dispatch(setSpecialty(newSpecialty));
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <SpecialtySelect
            selected={appointmentScheduleParams.specialty}
            onChange={onSpecialtyChange}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="left"
        >
          <DoctorSelect
            specialtyId={appointmentScheduleParams.specialty?.specialtyId}
            selected={appointmentScheduleParams.doctor}
            onChange={onDoctorChange}
            error={countErrors("doctor", errors) > 0}
          />
          <FieldErrors
            errors={getFieldErrors("doctor", errors) as string[]}
          ></FieldErrors>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorPicker;
