import Layout from "../components/layout/Layout";
import Confirmation from "../components/schedule/Confirmation";

const ConfirmationPage = () => {
  return (
    <Layout>
      <Confirmation />
    </Layout>
  );
};

export default ConfirmationPage;
