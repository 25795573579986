import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setDailySlotsAvailable } from "../redux/appointment-scheduler-params";
import Doctor from "../models/doctor";
import { getList } from "../api/generics";
import DailySlotsAvailable from "../models/daily-slots-available";
import { SUCCESS } from "../utils/constants/tags";

interface DailySlotsAvailableProviderProps {
  children: React.ReactNode;
}

const DailySlotsAvailableProvider: React.FunctionComponent<
  DailySlotsAvailableProviderProps
> = ({ children }) => {
  const doctor = useSelector(
    (state: RootState) => state.appointmentScheduler.doctor
  );
  const dispatch = useDispatch();
  const fetchDailySlotsAvailable = async (doctor?: Doctor) => {
    if (doctor === undefined) {
      dispatch(setDailySlotsAvailable([]));
      return;
    }

    const additionalParams: Map<string, string> = new Map();

    const startTime = new Date();
    const endTime = new Date();
    // 14 days in the future offset
    const offset = 1000 * 60 * 60 * 24 * 14;
    endTime.setTime(endTime.getTime() + offset);

    const startDateStr = startTime.toISOString().split("T")[0];
    const endDateStr = endTime.toISOString().split("T")[0];

    additionalParams.set("start_date", startDateStr);
    additionalParams.set("end_date", endDateStr);

    const req = await getList<DailySlotsAvailable>(
      `/doctors/${doctor.doctorId}/daily_slots_available/`,
      0,
      0,
      additionalParams
    );

    if (req.status !== SUCCESS || req.data === undefined) {
      dispatch(setDailySlotsAvailable([]));
      return;
    }

    dispatch(setDailySlotsAvailable(req.data.items));
  };

  useEffect(() => {
    fetchDailySlotsAvailable(doctor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor]);

  return <>{children}</>;
};

export default DailySlotsAvailableProvider;
