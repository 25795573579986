export const formatDate = (date: Date): string => {
  const yearStr = `${date.getFullYear()}`;

  const month = date.getMonth() + 1;
  let monthStr = `${month}`;

  if (month < 10) {
    monthStr = `0${month}`;
  }

  const day = date.getDate();
  let dayStr = `${day}`;

  if (day < 10) {
    dayStr = `0${day}`;
  }

  const formatted = `${yearStr}-${monthStr}-${dayStr}`;
  return formatted;
};

export const formatDateEs = (date: Date): string => {
  const yearStr = `${date.getFullYear()}`;

  const month = date.getMonth() + 1;
  let monthStr = `${month}`;

  if (month < 10) {
    monthStr = `0${month}`;
  }

  const day = date.getDate();
  let dayStr = `${day}`;

  if (day < 10) {
    dayStr = `0${day}`;
  }

  const formatted = `${dayStr}/${monthStr}/${yearStr}`;
  return formatted;
};

export const formatUTCDateTime = (date: Date): string => {
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getYearsAvailableFromYear = (startingYear: number) => {
  let years: number[] = [];

  const currentYear = new Date().getFullYear();

  for (let year = startingYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
};

export const changeTZToUtc = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
};
