import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";
import Specialty from "../models/specialty";
import Doctor from "../models/doctor";
import DailySlotsAvailable from "../models/daily-slots-available";
import Patient from "../models/patient";
import Errors from "../models/errors";

export interface AppointmentSchedulerParamsState {
  specialty?: Specialty;
  doctor?: Doctor;
  dailySlotsAavailable: DailySlotsAvailable[];
  date?: string;
  time?: string;
  patient?: Patient;
  errors: Errors;
}

const initialState: AppointmentSchedulerParamsState = {
  dailySlotsAavailable: [],
  patient: {},
  errors: {},
};

const appointmentSchedulerParamsSlice = createSlice({
  name: "appointmentScheduler",
  initialState,
  reducers: {
    setSpecialty: (state, action: PayloadAction<Specialty | undefined>) => {
      state.specialty = action.payload;
    },
    setDoctor: (state, action: PayloadAction<Doctor | undefined>) => {
      state.doctor = action.payload;
    },
    setDailySlotsAvailable: (
      state,
      action: PayloadAction<DailySlotsAvailable[]>
    ) => {
      state.dailySlotsAavailable = action.payload;
    },
    setAppointmentDate: (state, action: PayloadAction<string | undefined>) => {
      state.date = action.payload;
      if (state.date === undefined) {
        state.time = undefined;
      }
    },
    setAppointmentTime: (state, action: PayloadAction<string | undefined>) => {
      state.time = action.payload;
    },
    setPatient: (state, action: PayloadAction<Patient | undefined>) => {
      state.patient = action.payload;
    },
    setErrors: (state, action: PayloadAction<Errors>) => {
      state.errors = action.payload;
    },
    resetScheduleParams: () => {
      return initialState;
    },
  },
});

export const {
  setSpecialty,
  setDoctor,
  setDailySlotsAvailable,
  setAppointmentDate,
  setAppointmentTime,
  setPatient,
  setErrors,
  resetScheduleParams,
} = appointmentSchedulerParamsSlice.actions;

export default appointmentSchedulerParamsSlice.reducer;
