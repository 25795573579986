import Layout from "../components/layout/Layout";
import Wizard from "../components/schedule/Wizard";

const SchedulerPage = () => {
  return (
    <Layout>
      <Wizard />
    </Layout>
  );
};

export default SchedulerPage;
