import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { RootState } from "../../store";
import {
  setAppointmentDate,
  setAppointmentTime,
} from "../../redux/appointment-scheduler-params";
import { formatDate } from "../../utils/dates";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import DailySlotsAvailable from "../../models/daily-slots-available";
import { FieldErrors } from "../form/FieldErrors";
import { getFieldErrors } from "../../models/errors";

const DateTimePicker = () => {
  const selectedDate = useSelector(
    (state: RootState) => state.appointmentScheduler.date
  );
  const selectedTime = useSelector(
    (state: RootState) => state.appointmentScheduler.time
  );

  const dailySlotsAvailable = useSelector(
    (state: RootState) => state.appointmentScheduler.dailySlotsAavailable
  );

  const errors = useSelector(
    (state: RootState) => state.appointmentScheduler.errors
  );

  const dispatch = useDispatch();

  const [dailySlotsAvailableMap, setDailySlotsAvailableMap] = useState<
    Map<string, DailySlotsAvailable>
  >(new Map());

  const updateDailySlotsAvailableMap = async (
    newDailySlotsAvailable: DailySlotsAvailable[]
  ) => {
    const newDailySlotsAvailableMap: Map<string, DailySlotsAvailable> =
      new Map();
    for (const daySlotsAvailable of newDailySlotsAvailable) {
      newDailySlotsAvailableMap.set(daySlotsAvailable.date, daySlotsAvailable);
    }

    setDailySlotsAvailableMap(newDailySlotsAvailableMap);
  };

  useEffect(() => {
    updateDailySlotsAvailableMap(dailySlotsAvailable);
  }, [dailySlotsAvailable]);

  const timePickerDisabled = selectedDate === undefined;
  const date: moment.Moment | null =
    selectedDate !== undefined ? moment(selectedDate) : null;
  const time = selectedTime;

  const onDateChange = (value: string | moment.Moment | null) => {
    const newDate = value as moment.Moment | undefined;

    if (newDate === undefined || !moment(value, true).isValid()) {
      dispatch(setAppointmentDate(undefined));
      return;
    }

    const newDateStr = formatDate(
      new Date((value as moment.Moment).toISOString())
    );

    dispatch(setAppointmentDate(newDateStr));
  };

  const onTimeClick = (time: string) => {
    dispatch(setAppointmentTime(time));
  };

  const shouldDisableDate = useCallback(
    (date: moment.Moment) => {
      const dateStr = date.toISOString().split("T")[0];
      if (dailySlotsAvailableMap.has(dateStr)) {
        return false;
      }
      return true;
    },
    [dailySlotsAvailableMap]
  );

  let hourChoices: string[] = [];

  if (selectedDate !== undefined && dailySlotsAvailableMap.has(selectedDate)) {
    hourChoices = dailySlotsAvailableMap.get(selectedDate)!.times;
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Fecha
        </Typography>
        <FieldErrors
          errors={getFieldErrors("date", errors) as string[]}
        ></FieldErrors>
        <DateCalendar
          value={date}
          onChange={onDateChange}
          shouldDisableDate={shouldDisableDate}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Stack spacing={1} direction="column">
          <Typography component="h2" variant="h4" color="text.primary">
            Hora
          </Typography>
          <FieldErrors
            errors={getFieldErrors("time", errors) as string[]}
          ></FieldErrors>
          {hourChoices.map((choice, idx) => (
            <Button
              key={idx}
              variant={choice === time ? "contained" : "outlined"}
              disabled={timePickerDisabled}
              onClick={() => {
                onTimeClick(choice);
              }}
            >
              {choice}
            </Button>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DateTimePicker;
