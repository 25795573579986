import { Autocomplete, CircularProgress, Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Specialty from "../../models/specialty";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";

const FITER_TIMEOUT_MS = 500;

interface SpecialtySelectProps {
  onChange: (value?: Specialty) => void;
  size?: "small" | "medium" | undefined;
  margin?: "normal" | "none" | "dense" | undefined;
  selected?: Specialty;
}

const SpecialtySelect: React.FC<SpecialtySelectProps> = ({
  onChange,
  size,
  margin,
  selected,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [options, setOptions] = useState<Specialty[]>([]);
  const [loading, setLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const fetchNewOptions = useCallback(
    async (newSearch?: string) => {
      let additionalParams: Map<string, string> | undefined = new Map<
        string,
        string
      >();

      if (newSearch !== undefined && newSearch !== "") {
        additionalParams.set("search", newSearch);
      }

      if (
        newSearch !== undefined &&
        newSearch !== "" &&
        newSearch === selected?.description
      ) {
        return;
      }

      setLoading(true);

      if (search !== "") {
        additionalParams.set("search", search);
      }

      const req = await getList<Specialty>(
        "/specialties/",
        0,
        0,
        additionalParams
      );

      if (req.status !== SUCCESS) {
        setOptions([...options]);
        setCanLoadMore(false);
        setLoading(false);
      }

      const hasMore = false;

      setOptions(req.data!.items);
      setCanLoadMore(hasMore);

      setLoading(false);
    },
    [search, options, canLoadMore]
  );

  const loadWithTimeout = useCallback(
    async (newSearch?: string) => {
      window.clearTimeout(timeoutId);
      const newTimeoutId = window.setTimeout(() => {
        fetchNewOptions(newSearch);
      }, FITER_TIMEOUT_MS);
      setTimeoutId(newTimeoutId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [search, selected, timeoutId, fetchNewOptions]
  );

  const onSearchChange = useCallback(
    (_: React.ChangeEvent<{}>, value: string, __: any) => {
      setSearch(value);
      loadWithTimeout(value);
    },
    [loadWithTimeout, search, selected, fetchNewOptions]
  );

  return (
    <Autocomplete
      id="AsyncSpecialtySelect"
      fullWidth
      size="small"
      noOptionsText={"Sin Opciones"}
      loadingText={"Buscando..."}
      open={open}
      value={selected !== undefined ? selected : null}
      clearOnEscape={false}
      onOpen={() => {
        setOpen(true);
        fetchNewOptions();
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={onSearchChange}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight ===
            listboxNode.scrollHeight
          ) {
            fetchNewOptions();
          }
        },
      }}
      onChange={(event: any, option: Specialty | null) => {
        onChange(option !== null ? option : undefined);
      }}
      getOptionLabel={(option: Specialty) => option.description}
      isOptionEqualToValue={(option: Specialty, value: Specialty) => {
        return option.specialtyId === value.specialtyId;
      }}
      getOptionKey={(option: Specialty) => option.specialtyId}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Especialidad"
          variant="outlined"
          margin={margin}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SpecialtySelect;
