import {
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { emptyValueOnUndefined } from "../../utils/fields";
import { setPatient } from "../../redux/appointment-scheduler-params";
import { getList } from "../../api/generics";
import Patient from "../../models/patient";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { countErrors, getFieldErrors } from "../../models/errors";

const FITER_TIMEOUT_MS = 500;

const PersonalDataForm = () => {
  const errors = useSelector(
    (state: RootState) => state.appointmentScheduler.errors
  );
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const patient = useSelector(
    (state: RootState) => state.appointmentScheduler.patient
  );
  const [searching, setSearching] = useState(false);
  const [notFoundAlertOpen, setNotFoundAlertOpen] = useState(false);
  const [foundAlertOpen, setFoundAlertOpen] = useState(false);

  const dispatch = useDispatch();

  const onDocumentNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPatient = {
        ...patient,
        documentNumber: e.target.value,
      };
      dispatch(setPatient(newPatient));
    },
    [patient]
  );

  const handleDocumentNumberSearch = useCallback(async () => {
    const additionalParams: Map<string, string> = new Map();
    if (
      patient?.documentNumber === undefined ||
      patient?.documentNumber === ""
    ) {
      return;
    }
    setSearching(true);
    additionalParams.set("document_number", patient.documentNumber);
    const req = await getList<Patient>("/patients/", 0, 0, additionalParams);

    if (
      req.status !== SUCCESS ||
      req.data === undefined ||
      req.data.items.length === 0
    ) {
      setSearching(false);
      setNotFoundAlertOpen(true);
      dispatch(setPatient({ documentNumber: patient?.documentNumber }));
      return;
    }

    setFoundAlertOpen(true);
    dispatch(setPatient(req.data.items[0]));
    setSearching(false);
  }, [patient]);

  const handleDocumentNumberSearchWithTimeout = useCallback(async () => {
    window.clearTimeout(timeoutId);
    const newTimeoutId = window.setTimeout(() => {
      handleDocumentNumberSearch();
    }, FITER_TIMEOUT_MS);
    setTimeoutId(newTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, timeoutId, handleDocumentNumberSearch]);

  const onFirstNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPatient = {
        ...patient,
        firstName: e.target.value,
      };
      dispatch(setPatient(newPatient));
    },
    [patient]
  );

  const onLastNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPatient = {
        ...patient,
        lastName: e.target.value,
      };
      dispatch(setPatient(newPatient));
    },
    [patient]
  );

  const onPhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPatient = {
        ...patient,
        phoneNumber: e.target.value,
      };
      dispatch(setPatient(newPatient));
    },
    [patient]
  );

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPatient = {
        ...patient,
        email: e.target.value,
      };
      dispatch(setPatient(newPatient));
    },
    [patient]
  );

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <FormGroup row>
            <TextField
              error={countErrors("patientDocumentNumber", errors) > 0}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="documentNumber"
              label="Cédula de Identidad"
              name="documentNumber"
              size="small"
              value={emptyValueOnUndefined(patient?.documentNumber)}
              onChange={onDocumentNumberChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!searching ? (
                      <IconButton
                        onClick={handleDocumentNumberSearchWithTimeout}
                      >
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <CircularProgress
                        size={25}
                        style={{ marginRight: "9px" }}
                        color="inherit"
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>
          <FieldErrors
            errors={getFieldErrors("patientDocumentNumber", errors) as string[]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <TextField
            error={countErrors("patientFirstName", errors) > 0}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Nombres"
            name="firstName"
            size="small"
            value={emptyValueOnUndefined(patient?.firstName)}
            onChange={onFirstNameChange}
          />
          <FieldErrors
            errors={getFieldErrors("patientFirstName", errors) as string[]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <TextField
            error={countErrors("patientLastName", errors) > 0}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Apellidos"
            name="lastName"
            size="small"
            value={emptyValueOnUndefined(patient?.lastName)}
            onChange={onLastNameChange}
          />
          <FieldErrors
            errors={getFieldErrors("patientLastName", errors) as string[]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <TextField
            error={countErrors("patientPhoneNumber", errors) > 0}
            variant="outlined"
            margin="normal"
            fullWidth
            id="phoneNumber"
            label="Nro. de Teléfono"
            name="phoneNumber"
            size="small"
            value={emptyValueOnUndefined(patient?.phoneNumber)}
            onChange={onPhoneNumberChange}
          />
          <FieldErrors
            errors={getFieldErrors("patientPhoneNumber", errors) as string[]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <TextField
            error={countErrors("patientEmail", errors) > 0}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            size="small"
            value={emptyValueOnUndefined(patient?.email)}
            onChange={onEmailChange}
          />
          <FieldErrors
            errors={getFieldErrors("patientEmail", errors) as string[]}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={notFoundAlertOpen}
        autoHideDuration={3000}
        onClose={() => setNotFoundAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message="Paciente no encontrado. Complete los datos manualmente!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setNotFoundAlertOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={foundAlertOpen}
        autoHideDuration={3000}
        onClose={() => setFoundAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message="Paciente encontrado!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setFoundAlertOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default PersonalDataForm;
