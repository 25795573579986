import { RouteObject } from "react-router-dom";
import SchedulerPage from "./pages/SchedulerPage";
import ConfirmationPage from "./pages/ConfirmationPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <SchedulerPage />,
  },
  {
    path: "/confirmation",
    element: <ConfirmationPage />,
  },
];

export default routes;
