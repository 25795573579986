import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateTimePicker from "./DateTimePicker";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import DoctorPicker from "./DoctorPicker";
import PersonalDataForm from "./PersonalDataForm";
import { isPatientComplete } from "../../models/patient";
import { createItem } from "../../api/generics";
import ConfirmStep from "./ConfirmStep";
import { SUCCESS } from "../../utils/constants/tags";
import { useNavigate } from "react-router-dom";
import Errors from "../../models/errors";
import { setErrors } from "../../redux/appointment-scheduler-params";

const SELECTING_DOCTOR = 0;
const SELECTING_DATE_TIME = 1;
const FILLING_PATIENT_DETAILS = 2;
const CONFIRMING = 3;

const STEP_LABELS = [
  "Especialidad/Médico",
  "Fecha/Hora",
  "Paciente",
  "Confirmar",
];

const Wizard = () => {
  const dispatch = useDispatch();
  const appointmentDoctor = useSelector(
    (state: RootState) => state.appointmentScheduler.doctor
  );
  const appointmentDate = useSelector(
    (state: RootState) => state.appointmentScheduler.date
  );
  const appointmentTime = useSelector(
    (state: RootState) => state.appointmentScheduler.time
  );
  const appointmentPatient = useSelector(
    (state: RootState) => state.appointmentScheduler.patient
  );
  const [submitting, setSubmitting] = useState(false);
  const [failedSubmitAlertOpen, setFailedSubmitAlertOpen] = useState(false);
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(SELECTING_DOCTOR);
  const checkErrorsUntilCurrentStep = useCallback(() => {
    const newErrors: Errors = {};
    if (activeStep >= SELECTING_DOCTOR) {
      if (appointmentDoctor === undefined) {
        newErrors["doctor"] = ["Debe seleccionar un Doctor"];
      }
    }

    if (activeStep >= SELECTING_DATE_TIME) {
      if (appointmentDate === undefined) {
        newErrors["date"] = ["Debe seleccionar una Fecha"];
      }

      if (appointmentTime === undefined) {
        newErrors["time"] = ["Debe seleccinoar una Hora"];
      }
    }

    if (activeStep >= FILLING_PATIENT_DETAILS) {
      if (appointmentPatient?.documentNumber === undefined) {
        newErrors["patientDocumentNumber"] = [
          "Debe introducir la cédula del paciente",
        ];
      }

      if (appointmentPatient?.firstName === undefined) {
        newErrors["patientFirstName"] = [
          "Debe introducir los nombres del paciente",
        ];
      }

      if (appointmentPatient?.lastName === undefined) {
        newErrors["patientLastName"] = [
          "Debe introducir los apellidos del paciente",
        ];
      }
    }
    dispatch(setErrors(newErrors));
  }, [
    appointmentDoctor,
    appointmentDate,
    appointmentTime,
    appointmentPatient,
    activeStep,
  ]);
  const requirementsMetForStep = useCallback(
    (step: number) => {
      if (step === SELECTING_DOCTOR) {
        return true;
      }

      if (step === SELECTING_DATE_TIME && appointmentDoctor !== undefined) {
        return true;
      }

      if (
        step === FILLING_PATIENT_DETAILS &&
        appointmentDoctor !== undefined &&
        appointmentDate !== undefined &&
        appointmentTime !== undefined
      ) {
        return true;
      }

      if (
        step >= CONFIRMING &&
        appointmentDoctor !== undefined &&
        appointmentDate !== undefined &&
        appointmentTime !== undefined &&
        isPatientComplete(appointmentPatient)
      ) {
        return true;
      }
      return false;
    },
    [appointmentDoctor, appointmentDate, appointmentTime, appointmentPatient]
  );
  const handleStepChange = useCallback(
    (newStep: number) => {
      if (!requirementsMetForStep(newStep)) {
        checkErrorsUntilCurrentStep();
        return;
      }

      checkErrorsUntilCurrentStep();
      setActiveStep(newStep);
    },
    [requirementsMetForStep, checkErrorsUntilCurrentStep]
  );

  const handleConfirm = useCallback(async () => {
    if (appointmentDoctor === undefined) {
      return;
    }

    if (appointmentDate === undefined) {
      return;
    }

    if (appointmentTime === undefined) {
      return;
    }

    if (!isPatientComplete(appointmentPatient)) {
      return;
    }
    const data = {
      branchOfficeId: 1,
      documentNumber: appointmentPatient!.documentNumber!,
      firstName: appointmentPatient!.firstName!,
      lastName: appointmentPatient!.lastName!,
      sex: appointmentPatient?.sex !== undefined ? appointmentPatient.sex : "I",
      birthDate:
        appointmentPatient?.birthDate !== undefined
          ? appointmentPatient.birthDate
          : null,
      address:
        appointmentPatient?.address !== undefined
          ? appointmentPatient.address
          : null,
      phoneNumber: appointmentPatient!.phoneNumber!,
      email: appointmentPatient!.email!,
      comment: "Agendamiento Online",
      doctorDocumentNumber: appointmentDoctor.documentNumber,
      date: appointmentDate,
      time: appointmentTime,
    };

    setSubmitting(true);

    const res = await createItem("/create/", data);
    if (res.status === SUCCESS) {
      navigate("/confirmation");
    } else {
      setFailedSubmitAlertOpen(true);
    }

    setSubmitting(false);
  }, [appointmentDoctor, appointmentDate, appointmentTime, appointmentPatient]);

  const handleNext = useCallback(() => {
    const newStep = activeStep + 1;
    if (newStep > CONFIRMING) {
      checkErrorsUntilCurrentStep();
      handleConfirm();
      return;
    }

    if (!requirementsMetForStep(newStep)) {
      checkErrorsUntilCurrentStep();
      return;
    }
    checkErrorsUntilCurrentStep();
    setActiveStep(newStep);
  }, [
    activeStep,
    requirementsMetForStep,
    handleConfirm,
    checkErrorsUntilCurrentStep,
  ]);

  const handleBack = useCallback(() => {
    const newStep = activeStep - 1;
    if (newStep < 0) {
      return;
    }

    if (!requirementsMetForStep(newStep)) {
      checkErrorsUntilCurrentStep();
      return;
    }

    checkErrorsUntilCurrentStep();
    setActiveStep(newStep);
  }, [activeStep, requirementsMetForStep]);

  return (
    <>
      <Container id="features" sx={{ py: { xs: 14, sm: 16 } }}>
        <Grid container spacing={12}>
          <Grid item xs={12} md={12}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
              {STEP_LABELS.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    disabled={!requirementsMetForStep(index)}
                    color={"inherit"}
                    onClick={() => handleStepChange(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={12}>
            {activeStep === SELECTING_DOCTOR ? <DoctorPicker /> : <></>}
            {activeStep === SELECTING_DATE_TIME ? <DateTimePicker /> : <></>}
            {activeStep === FILLING_PATIENT_DETAILS ? (
              <PersonalDataForm />
            ) : (
              <></>
            )}
            {activeStep === CONFIRMING ? <ConfirmStep /> : <></>}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Atrás
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={submitting}
              >
                {activeStep === STEP_LABELS.length - 1
                  ? "Confirmar"
                  : "Siguiente"}
                {submitting && <CircularProgress size={24} color="inherit" />}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={failedSubmitAlertOpen}
        autoHideDuration={3000}
        onClose={() => setFailedSubmitAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message="Ha ocurrido un error. Por favor, vuelva a intentarlo!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setFailedSubmitAlertOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default Wizard;
