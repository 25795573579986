import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Doctor from "../../models/doctor";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";

const FITER_TIMEOUT_MS = 500;

interface DoctorSelectProps {
  onChange: (value?: Doctor) => void;
  margin?: "normal" | "none" | "dense" | undefined;
  selected?: Doctor;
  branchOfficeId?: number;
  specialtyId?: string;
  error?: boolean;
}

const DoctorSelect: React.FC<DoctorSelectProps> = ({
  onChange,
  margin,
  selected,
  branchOfficeId,
  specialtyId,
  error,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const [options, setOptions] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const fetchNewOptions = useCallback(
    async (newSearch?: string) => {
      let additionalParams: Map<string, string> | undefined = new Map<
        string,
        string
      >();

      if (newSearch !== undefined && newSearch !== "") {
        additionalParams.set("search", newSearch);
      }

      if (
        newSearch !== undefined &&
        newSearch !== "" &&
        newSearch === selected?.fullName
      ) {
        return;
      }

      setLoading(true);

      if (specialtyId !== undefined) {
        additionalParams.set("specialty_id", specialtyId);
      }

      if (branchOfficeId !== undefined) {
        additionalParams.set("branch_office_id", branchOfficeId.toString());
      } else {
        additionalParams.set("branch_office_id", "1");
      }

      const req = await getList<Doctor>("/doctors/", 0, 0, additionalParams);

      if (req.status !== SUCCESS) {
        setOptions([...options]);
        setCanLoadMore(false);
        setLoading(false);
      }

      const hasMore = false;

      const newOptions = req!.data!.items;

      setOptions(newOptions);
      setCanLoadMore(hasMore);

      setLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [search, selected, specialtyId, branchOfficeId, options, canLoadMore, open]
  );

  const loadWithTimeout = useCallback(
    async (newSearch?: string) => {
      window.clearTimeout(timeoutId);
      const newTimeoutId = window.setTimeout(() => {
        fetchNewOptions(newSearch);
      }, FITER_TIMEOUT_MS);
      setTimeoutId(newTimeoutId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [search, selected, timeoutId, fetchNewOptions]
  );

  const onSearchChange = useCallback(
    (_: React.ChangeEvent<{}>, value: string, __: any) => {
      setSearch(value);
      loadWithTimeout(value);
    },
    [loadWithTimeout, search, selected, fetchNewOptions]
  );

  let key = "AsyncDoctorSelect";

  if (branchOfficeId !== undefined) {
    key = `${key}-${branchOfficeId}`;
  }

  if (specialtyId !== undefined) {
    key = `${key}-${specialtyId}`;
  }

  return (
    <Autocomplete
      id="AsyncDoctorSelect"
      key={key}
      fullWidth
      size="small"
      noOptionsText={"Sin Opciones"}
      loadingText={"Buscando..."}
      open={open}
      value={selected !== undefined ? selected : null}
      clearOnEscape={false}
      onOpen={() => {
        fetchNewOptions(search);
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={onSearchChange}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollTop + listboxNode.clientHeight ===
            listboxNode.scrollHeight
          ) {
            fetchNewOptions();
          }
        },
      }}
      onChange={(event: any, option: Doctor | null) => {
        setOpen(false);
        onChange(option !== null ? option : undefined);
      }}
      getOptionLabel={(option: Doctor) => option.fullName}
      isOptionEqualToValue={(option: Doctor, value: Doctor) => {
        return option.doctorId === value.doctorId;
      }}
      getOptionKey={(option: Doctor) => option.doctorId}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Médico"
          variant="outlined"
          margin={margin}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default DoctorSelect;
