interface Patient {
  patientId?: string;
  documentNumber?: string;
  firstName?: string;
  lastName?: string;
  sex?: string;
  birthDate?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
  insurance?: string;
  insurancePlan?: string;
}

export const isPatientComplete = (patient: Patient | undefined) => {
  if (patient?.documentNumber === undefined) {
    return false;
  }

  if (patient?.firstName === undefined) {
    return false;
  }

  if (patient?.lastName === undefined) {
    return false;
  }

  return true;
};

export default Patient;
