import { Grid } from "@mui/material";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { formatDateEs } from "../../utils/dates";

const ConfirmStep = () => {
  const appointmentDoctor = useSelector(
    (state: RootState) => state.appointmentScheduler.doctor
  );
  const appointmentDate = useSelector(
    (state: RootState) => state.appointmentScheduler.date
  );
  const appointmentTime = useSelector(
    (state: RootState) => state.appointmentScheduler.time
  );
  const appointmentPatient = useSelector(
    (state: RootState) => state.appointmentScheduler.patient
  );
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <h2>Profesional Seleccionado</h2>
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Nombre:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentDoctor?.fullName}
        </Grid>
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <h2>Paciente</h2>
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Nombres:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentPatient?.firstName}
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Apellidos:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentPatient?.lastName}
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Cédula de Identidad:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentPatient?.documentNumber}
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Seguro:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentPatient?.insurance !== undefined &&
          appointmentPatient.insurance !== ""
            ? appointmentPatient.insurance
            : "-"}
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Plan:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentPatient?.insurancePlan !== undefined &&
          appointmentPatient.insurancePlan !== ""
            ? appointmentPatient.insurancePlan
            : "-"}
        </Grid>
        <Grid
          item
          xs={12}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          <h2>Datos Consulta</h2>
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Fecha:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentDate !== undefined
            ? formatDateEs(new Date(`${appointmentDate}T00:00:00`))
            : "-"}
        </Grid>
        <Grid
          item
          xs={3}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          Hora:
        </Grid>
        <Grid
          item
          xs={9}
          direction="column"
          alignItems="left"
          justifyContent="left"
          textAlign="left"
        >
          {appointmentTime !== undefined ? appointmentTime : "-"}
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmStep;
