import { Button, Container, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetScheduleParams } from "../../redux/appointment-scheduler-params";

const Confirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onGoToStartClick = () => {
    dispatch(resetScheduleParams());
    navigate("/");
  };
  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={12}>
        <Grid
          item
          xs={12}
          md={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <h2>Muchas gracias por su agendamiento. Le esperamos!</h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Button variant="contained" onClick={onGoToStartClick} sx={{ mr: 1 }}>
            Inicio
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Confirmation;
