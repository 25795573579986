import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import store from "./store";
import { Provider } from "react-redux";
import DailySlotsAvailableProvider from "./providers/DailySlotsAvailableProvider";

const getApp = () => {
  return (
    <Provider store={store}>
      <DailySlotsAvailableProvider>
        <App />
      </DailySlotsAvailableProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(getApp());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
